import React from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";
import { imageProps } from "@atoms";
import { CrossFader, CarouselSlides } from "@composites";

const PhilosophyLargeImages = ({ images }) => {
  const [{ philosophyPage }, dispatch] = useAppState();

  if (images.length === 1) {
    return <CarouselSlides.BackgroundImage image={images[0]} />;
  }

  return (
    <CrossFader
      currentSlide={philosophyPage.currentSlide}
      setCurrentSlide={currentSlide =>
        dispatch({ type: "setCurrentSlide", currentSlide })
      }
      nav="arrows"
    >
      {images.map(
        React.useCallback((image, idx) => (
          <CarouselSlides.BackgroundImage key={idx} image={image} />
        ))
      )}
    </CrossFader>
  );
};

PhilosophyLargeImages.propTypes = {
  images: PropTypes.arrayOf(imageProps).isRequired,
};

export default PhilosophyLargeImages;
