import React from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";
import { TestimonialFader } from "@composites";

const PhilosophyTestimonials = ({ className, ...rest }) => {
  const [{ philosophyPage }, dispatch] = useAppState();

  return (
    <TestimonialFader
      {...rest}
      currentSlide={philosophyPage.currentSlide}
      setCurrentSlide={currentSlide =>
        dispatch({ type: "setCurrentSlide", currentSlide })
      }
      className={`relative z-30 ${className}`}
    />
  );
};

PhilosophyTestimonials.propTypes = {
  className: PropTypes.string,
};

PhilosophyTestimonials.defaultProps = {
  className: "",
};

export default PhilosophyTestimonials;
