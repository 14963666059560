import React from "react";
import { usePhilosophyPageData } from "@staticQueries";
import { DefaultLayout } from "@layout";
import {
  Section,
  Container,
  RawText,
  Pattern,
  Triangle,
  Text,
  RichText,
} from "@atoms";
import {
  CopyBlock,
  ImageBlock,
  CopyImageBlock,
  StackedCard,
  CardGroup,
  CompanyGrid,
} from "@composites";
import { Link, Button } from "@ui";

import PhilosophyTestimonials from "./PhilosophyTestimonials";
import PhilosophyLargeImages from "./PhilosophyLargeImages";

const PhilosophyPage = pageProps => {
  const {
    hero,
    overview,
    testimonials,
    disruptions,
    companies,
    prefooter,
    meta,
  } = usePhilosophyPageData();

  return (
    <DefaultLayout {...pageProps} meta={meta} theme="light">
      {/* Hero */}
      <Section className="relative bg-gray-5" size="xl">
        <Container>
          <RawText items={hero.heading.raw} className="text-green-2 max-w-sm" />
        </Container>

        <Pattern
          orientation="bottom-left"
          size="sm"
          className="text-gray-3"
          doAnimation
        />
      </Section>

      {/* Portfolio Overview */}
      <Section className="relative bg-white">
        <Pattern
          svgName="HomeHeroPattern"
          orientation="top-left"
          size="sm"
          className="text-gray-3 z-20"
        />

        <Container>
          <CopyImageBlock
            orientation="left"
            copy={({ className, style }) => (
              <CopyBlock
                {...overview}
                preset="dark"
                className={className}
                style={style}
              />
            )}
            image={({ className, style }) => (
              <ImageBlock
                image={overview.image}
                className={className}
                style={style}
              />
            )}
          />
        </Container>
      </Section>

      {/* Large Image Cross-Fader */}
      {testimonials.length > 0 && (
        <Section className="bg-white" hasPaddingBottom={false}>
          <PhilosophyLargeImages
            images={testimonials.map(({ image }) => image)}
          />

          <Pattern
            orientation="bottom-right"
            size="sm"
            className="text-gray-3 z-20"
          />
        </Section>
      )}

      {/* Testimonials & Disruptions */}
      <Section className="bg-gray-5" hasPaddingTop={false} allowOverflow>
        <div className="absolute inset-0 overflow-hidden">
          <Pattern
            orientation="top-right"
            size="sm"
            className="text-gray-3 z-20"
          />
        </div>

        <PhilosophyTestimonials
          testimonials={testimonials}
          className={`${
            testimonials.length > 0 ? "-translate-y-16" : "mt-16 mb-6"
          }`}
        />

        <Container className="relative z-10 mt-8 md:mt-16">
          <Text tag="label" className="block text-green-3 mb-4">
            {disruptions.subheading}
          </Text>

          <RawText
            items={disruptions.heading.raw}
            className="text-green-2 md:w-1/2"
          />
        </Container>

        <Container className="relative z-10 mt-8 md:mt-12 lg:mt-16">
          <CardGroup
            gutter={6}
            cards={disruptions.items.map(({ item }, idx) =>
              React.useCallback(({ className }) => (
                <StackedCard
                  className={className}
                  top={() => (
                    <div
                      className="font-mono text-green-3 -mb-3"
                      style={{ fontSize: 105 }}
                    >
                      {`${idx + 1}`.padStart(2, "0")}
                    </div>
                  )}
                  bottom={() => (
                    <RichText
                      html={item.html}
                      className="RichText--sm text-green-1"
                    />
                  )}
                />
              ))
            )}
          />
        </Container>
      </Section>

      <Section className="bg-white" size="xl">
        <Container className="relative z-10 mt-8 md:mt-16">
          <Text tag="label" className="block text-green-3 mb-4">
            {companies.subheading}
          </Text>

          <RawText
            items={companies.heading.raw}
            className="text-green-2 md:w-1/2"
          />

          <CompanyGrid
            companies={companies.companies}
            className="my-12 lg:my-20"
          />

          <div className="flex justify-center">
            <Link to={companies.ctaLink.url} target={companies.ctaLink.target}>
              <Button preset="light" text={companies.ctaText} />
            </Link>
          </div>
        </Container>
      </Section>

      {/* Prefooter */}
      <Section className="bg-white" allowOverflow>
        <Triangle
          orientation="bottom-right"
          className="text-gray-5 -mt-24 md:-mt-48"
        />

        <Container class="relative">
          <CopyImageBlock
            orientation="left"
            className="bg-green shadow-section"
            copy={({ style }) => (
              <div className="relative overflow-hidden">
                <CopyBlock
                  {...prefooter}
                  preset="light"
                  className="relative z-10 px-6 pb-6 sm:px-8 sm:pb-8 md:p-16"
                  style={style}
                />
                <Pattern
                  orientation="bottom-left"
                  size="sm"
                  className="text-gray-2"
                />
              </div>
            )}
            image={({ className, style }) => (
              <ImageBlock
                image={prefooter.image}
                className={className}
                style={style}
              />
            )}
          />
        </Container>
      </Section>
    </DefaultLayout>
  );
};

export default PhilosophyPage;
